/*Cart page*/

/*cart page Override*/
.cart-action-buttons button.btn-continue.btn-inline, .cart-action-buttons #save-cart.btn-inline, .cart-action-buttons button.btn-update.btn-save-change.btn-inline,.cart-action-buttons button.btn-clear.btn-inline,.cart-action-buttons button.btn-proceed-checkout-action.btn-proceed-checkout.btn-checkout.btn-inline {
    margin-right:15px;
}
.product-view .btn-cart span, button.button span {
    margin:0;
    background-color:#d9b256;
    border-radius:10px;
    padding:0;
}
.product-view .btn-cart span span, button.button span span {
    border:1px solid #d9b256;
    border-radius:7px;
}
/*Hide secondary description in cart*/
#shopping-cart-table .product-name .item-secondary-heading {
    display:none;
    font-size:12px;
}
/*hide active promotion button as per UAT*/
button.button.discount_button[title="Active Promotions"] {
    display:none;
}

#shopping-cart-table th.a-center {
    white-space: nowrap;
    text-align: left !important;
}
td.col-unit-price.a-right {
    text-align: left !important;
}

#shopping-cart-table .a-center {
    text-align: center!important;
    font-size: 12px;
}
.product-view .btn-cart span span, button.button span span {
    border: 1px solid #d9b256;
    border-radius: 7px;
    color: #000;
}

/*Checkout Page*/
/*Border styling*/
.page-type-cart #checkout-review-table-wrapper input:not(#promo):not([name=terms_conditions]), .page-type-cart #checkout-review-table-wrapper textarea,
input#promo.input-text
{
    width: 70%;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
}

/*Reduce font size of h2 titles*/
.block .block-title, .cart .crosssell h2, .cart .discount h2, .cart .shipping h2, .section-title {
    font-size: 1.2em !important;
}

/*hiding colour field label and adding comments and instructions as a label*/
#checkout-review-table-wrapper > div:nth-child(4) > h2 {
    display: none;
}
.page-type-cart #checkout-review-table-wrapper .rego-field #comments-label::before {
    content: "Comments or Instructions";
    padding: 10px 0;
    display: block;
    border-bottom: 1px solid #e5e5e5;
    font-weight: 400;
    font-size: 1.2em !important;
    margin-bottom: 2%;
}
/*Summary Table font size and width adjustment*/
#opc-review .product-name a, 
#opc-review .product-name p, 
.item-options dd, 
.item-options dt, 
.a-right,
.cart-price, span.cart-price span.price,
.custom_comment_container,
.checkout-qty-bo,
span#comment-bf8689a777f47cb27015cf256beb6b7f {
    font-size: 12px;
}
#shopping-cart-table .product_comment a {
    text-decoration: underline;
    font-size: 12px !important;
    line-height: normal !important;
}
.a-left {
    text-align: left!important;
    width: 100%;
}
.data-table thead th {
    font-size: 14px;
}
span#comment-bf8689a777f47cb27015cf256beb6b7f {
    line-height: 12px;
}
tr.sub-total-item td.a-right {
    font-size: 16px;
}

/*Font styling on cart and checkout*/
.page-type-cart a, .page-type-cart body, .page-type-cart i, .page-type-cart p, .page-type-cart span, .page-type-cart u {
    font-size: 12px !important;
}
.page-type-cart h1 {
    font-size: 1.6666em !important;
    line-height: 1.25em;
}
.page-type-cart h2 {
    font-size: 1.2em !important;
    line-height: 1em;
}