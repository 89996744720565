@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #333;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
/*Overwritten in home.css*/
/*h1 {
	font-size:26px;
	font-family: 'Montserrat', sans-serif;
	font-weight:500;
	color:#C83A2F;
}*/
h2 {
	font-size:22px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:20px;
	font-family: 'Montserrat', sans-serif;
}
h3 {
	font-size:16px;
	font-weight:bold;
	line-height:inherit;
	font-family: 'Montserrat', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'Montserrat', sans-serif;
}
h6 {
	font-size:24px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Montserrat', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'Montserrat', sans-serif !important;
}
/*overwritten in productdetail.css*/
/*.gen-tabs .tabs a.current {
	color:#C83A2F;
}*/

i.fa {
	font-style: normal;
}
/*Hidden as background colour not needed.*/
/*.no-gutter.grey-back .nested-container {
	background-color: #f2f2f2;
	padding: 2% 0;
}*/
.no-gutter.grey-back.no-padding .nested-container {
	padding: 0;
}
.main .col-main.no-gutter.grey-back {
	margin: 0;
}
.main .col-main.no-gutter.grey-back + div.col-main {
	margin-top: 0;
}
.grey-back img {
	border-radius: 10px;
}
.grey-back .picture-text-combo {
	background-color: #fff;
}
.grey-back .picture-text-combo > span {
	border-radius: 10px;
	display: inline-block;
	vertical-align: middle;
}
.side-picture-text-combo {
    background-color: #fff;
    border-radius: 20px;
    text-align: center;
    color: #c83a2f;
    padding-bottom: 10px;
}
.main .col-main.grid12-12.container {
	float: none;
	display: block;
	margin-left: auto;
	margin-right: auto;
	clear: both;
}
.keep-width.grid12-1 {
	width: 6.33% !important;
}
/*Overwritten in home.css*/
/*.keep-width.grid12-2 {
	width: 14.66% !important;
}*/
.keep-width.grid12-3 {
	width: 23% !important;
}
.keep-width.grid12-4 {
	width: 31.33% !important;
}
.keep-width.grid12-5 {
	width: 39.67% !important;
}
.keep-width.grid12-6 {
	width: 48% !important;
}
.keep-width.grid12-7 {
	width: 56.33% !important;
}
.keep-width.grid12-8 {
	width: 64.67% !important;
}
.keep-width.grid12-9 {
	width: 73% !important;
}
.keep-width.grid12-10 {
	width: 81.33% !important;
}
.keep-width.grid12-11 {
	width: 89.67% !important;
}
@media only screen and (min-width: 960px) {
	.show-below-960, .hide-above-960 {
		display: none !important;
	}
	.grey-back .picture-text-combo > span:first-of-type {
		width: 60%;
	}
	.grey-back .picture-text-combo > span:first-of-type + span {
		width: calc(40% - 65px);
		padding: 0 30px;
	}
}
@media only screen and (max-width: 959px) {
	.hide-below-960 {
		display: none !important;
	}
	.grey-back .picture-text-combo > span:first-of-type + span {
		padding: 30px;
	}
}
/**************************[ HEADER ]**************************/
.xm-grid-header > .container {
	max-width: none;
	width: 100%;
	margin: 0;
	padding: 0;
}
.xm-grid-header .no-gutter > .std > .nested-container {
	width: 100%;
	margin: 0;
}
/*Hiding to make header elements full width*/
/*.desktop-top-header-container div[class*="grid12-"] {
	width: auto !important;
}*/
/*Overwritten in header.css*/
/*.desktop-top-header-container .nested-container {
	background-color: #f2f2f2;
	padding-top: 5px;
}*/
.desktop-top-header-container .links > li > a::after {
	content: "\00a0|\00a0";
	/* font-weight: bold; */
	font-size: 18px;
}
.desktop-top-header-container a, .desktop-top-header-container span {
	line-height: 3em;
}
.desktop-top-header-container .dropdown-menu a {
	line-height: normal;
}
#root-wrapper .desktop-top-header-container #mini-cart span.fa.fa.fa-shopping-cart {
	line-height: 1.5em;
}
/*Overwritten in header.css*/
/*.header a, .header p, .header span {
	font-size: 14px;
	color: #fff;
	font-family: 'Montserrat', sans-serif;
}*/
.header .dropdown, .header-top {
	line-height: normal;
}
.header-main-container {
	padding: 20px 0;
}
.header-main-container  ul#nav.nav-regular {
	display: flex;
	justify-content: center;
}
ul#nav.nav-regular {
	margin-top: 10px;
}
/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}

.header-top-container .header-top {
	line-height: normal;
}

.top-phone-column span {
	line-height: 3em;
}
.mobile-header-container {
	margin: 0;
	padding: 5px 0;
}
.mobile-header-container .grid12-1 {
	width: 6.33% !important;
}
.mobile-header-container .grid12-2 {
	width: 14.66% !important;
}
.mobile-header-container .grid12-10 {
	width: 81.33% !important;
}
.mobile-header-container .grid12-11 {
	width: 89.67% !important;
}
.mobile-header-container div[class*="grid12-"] {
	margin-left: 1%;
	margin-right: 1%;
}
/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family: 'Montserrat', sans-serif;
	display: block;
}
.module-search.search-wrapper .form-search .button-search {
	position: absolute;
	top: 4px;
	right: 4px;
	/*background-color: #f2f2f2;*/ /*Hiding backround search colour*/
	border-radius: 50%;
}
.module-search.search-wrapper .form-search .button-search .fa {
	font-size: 16px;
	color: #777;
	padding: 11px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}
/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #C83A2F;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #C83A2F;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff;
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}
#root-wrapper .mobile-header-container i.fa {
	padding: .5em;
}
#mini-cart .fa.fa-shopping-cart, .mobile-header-container .fa.fa-shopping-cart {
	background-image: url(https://cdn.staging-mysagestore.com/fd5237bc62083a5d35cfdf6634ccb54b/contents/ckfinder/images/cart.png);
	width: 22px;
	height: 23px;
	background-position: center;
	background-repeat: no-repeat;
	color: #777;
}
#mini-cart .fa.fa-shopping-cart::before, .mobile-header-container .fa.fa-shopping-cart::before {
	content: none;
}
.mobile-header-container .fa.fa-shopping-cart ~ div, .mobile-header-container .feature-icon-hover .caret {
	display: none;
}
.nav-regular li.level0.request-quote>a {
	background-color:#c83a2f;
	border-radius:30px;
	padding:0 24px;
}
.nav-regular li.level0.request-quote>a>span {
	color:#fff;
	font-size:16px;
}
#nav.nav-regular li.nav-item.level0.request-quote:hover {
	border:none;
}
@media only screen and (min-width: 960px) {
	.header .fa.fa-shopping-cart {
		top: 8px;
		left: -15px;
	}
	.welcome-msg *, .userCode, .userName {
		color: #333 !important;
	}
}
@media only screen and (max-width: 959px) {
	#header {
		position: fixed;
		width: 100%;
		z-index: 9999;
	}
	.welcome-msg *, .userCode, .userName, .mobile-header-container div.nav-search:not(.fly-out) .fa.fa-search {
		color: #777 !important;
	}
	.logo img {
		width: 70%;
	}
}
/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #333;
	font-size: 14px;
	font-family: 'Montserrat', sans-serif;
}

#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
/*Overwritten in header.css*/
/*#nav.nav-regular li.nav-item.level0:hover {
	border-bottom: 2px solid #C83A2F;

}*/
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #C83A2F;
}
/*Overwritten in header.css*/
/*.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #C83A2F;
}*/
#nav .nav-panel--dropdown a {
	color: #777;
	font-size: 14px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 14px;
}
/*Overwritten in Header.css*/
/*#nav .nav-panel--dropdown a:hover {
	color: #C83A2F;
}*/
.mobnav-trigger.active {
	background-color: #C83A2F;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #777;
}
#nav .nav-item--home {
	display: none;
}
#nav .nav-item--home .fa-home {
	color: #C83A2F !important;
	padding: 0.3rem;
}
/*Overwritten in Header.css*/
/*#root-wrapper a:hover span.fa:before {
	color: #C83A2F;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #C83A2F;
}*/
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #C83A2F;
	border-right-color: #C83A2F;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
/*Overwritten in header.css*/
/*#mini-cart .actions button.button span span {
	background-color: #C83A2F !important;
	padding: 0;
	border-radius: 0;
	max-height: 45px;
	font-size: 16px;
	margin-top: 10px;
}*/
#mini-cart .actions button.button span span:hover {
	background-color: #fff !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}
#root-wrapper .mh-category-btn span.fa.fa-bars.userCode {
	padding-left: 0;
	padding-right: 0;
	margin-right: 0;
}
@media only screen and (max-width: 959px) {
	.nav-search .input-text, .nav-search .form-search .button-close {
        display: none;
    }
	.nav-search.fly-out {
        position: fixed;
        display: block !important;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 9999;
        width: 100% !important;
        background-color: #fff;
    }
    .nav-search.fly-out .form-search {
        position: relative;
        padding: 10px;
    }
    .nav-search.fly-out .form-search .input-text {
        width: 100%;
        max-width: 100%;
        display: block;
        border: none;
        padding-right: 70px;
    }
    .nav-search.fly-out .form-search .button {
        color: #aaa;
        top: 10px;
        right: 10px;
    }
    .nav-search.fly-out .form-search .button-search {
        right: 45px;
    }
    .nav-search.fly-out #inline-search-results {
        top: 50px;
    }
    .nav-search.fly-out .form-search .button-close {
        display: block;
    }
	.nav-search.fly-out .form-search .button {
		position: absolute;
	}
}
/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
/*Overwritten in header.css*/
/*h1, h1.banner-h1, .banner-h1 a {
	font-size: 32px;
	color: #C83A2F;
}*/
.number-feature h1 {
	margin: 0;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
/*Overwritten in category and cart css*/
/*.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #C83A2F;
	border-radius: 30px;
	padding: 0;
}
.product-view .btn-cart span span, button.button span span {
	border:1px solid #C83A2F;
}*/
button, .button, .button a, button a {
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}
button.button span span {
	padding: 0 24px;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span, .button.inverted span {
	background-color: #fff !important;
	color: #333;
}
.btn-filled:hover, .button:hover span span, .button.inverted span span {
	border: 1px solid #333;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #43266d;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #43266d;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}
.multi-tab-container .multi-tab {
	cursor: pointer;
}

.multi-content-container .multi-content {
	display: none;
}
.multi-content-container .multi-content:first-of-type {
	/*display: block;*/
}
.grid12-12.no-gutter .grid12-12 > .page-content p img {
	width: 100%;
}
.full-width-img img {
	width: 100%;
}
.full-width-img p {
	margin-bottom: 0;
}
.main-container .main div[class*="grid12-"].col-main div[class*="grid12-"] ul li:not(.fields) {
	/*list-style: disc inside;*/
}
ul.bullets li, ol.bullets li {
	list-style: disc inside;
}
.vertical-padding {
    padding-top: 3em;
    padding-bottom: 3em;
}
/*Overwritten in home.css*/
/*.red-links a, a.red-link {
	color: #c83a2f !important;
}*/
@media only screen and (max-width: 959px) {
	.universal-sidebar img {
		width: 30px;
	}
	.universal-sidebar a {
		font-size: 20px !important;
	}
	.main-container {
		padding-top: 58px;
	}
	div#hs-eu-cookie-confirmation.hs-cookie-notification-position-bottom div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area {
		width: auto;
		width: initial;
		width: intrinsic;
		width: -moz-max-content;
		width: -webkit-max-content;
		margin: auto !important;
	}
}
@media only screen and (max-width: 767px) {
	.multi-tab-container .multi-tab p:first-of-type ~ p {
		display: none;
	}
}
/**************************[ Home ]**************************/
.main .col-main.multi-content-container, .main .col-main.multi-tab-container {
	margin-top: 0;
	margin-bottom: 0;
}
.main-banner-container .nested-container {
	position: relative;
	background-color:#EFEFEF;
}
.multi-tab-container .multi-tab {
	padding-top: 1em;
	padding-bottom: 1em;
}
.multi-tab-container .multi-tab p, .multi-tab-container .multi-tab a {
	font-size: 14px;
	margin: 0;
}
.multi-tab-container .multi-tab p, .multi-tab-container .multi-tab a {
	color: #333;
	font-weight: bold;
}
.multi-tab-container .multi-tab a {
	color: #C83A2F;
}
.multi-tab-container .multi-tab.active {
	background-color: #d9b256;
}
.main-banner-overlay {
	position: absolute;
	z-index: 1;
	width: 60%;
	height: 688px;
	top:0;
	left: 0;
	background-color: rgba(0,0,0,0.3);
}
.owl-carousel .owl-item img.message-bubble {
	width: auto;
}
.conversation-caption {
	position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
}
.banner-caption {
	position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
	right: 0;
	z-index: 2;
	
}
.conversation-caption > a {
	position: relative;
    left: -50%;
}
.conversation-caption > a > p:first-of-type br {
	display: none;
}
.conversation-caption p.sub-cat-title-caption br ~ br {
	display: none;
}
.conversation-caption .main-cat-title, .banner-caption .main-cat-title {
	font-weight: normal;
    font-size: 40px;
    line-height: normal;
}
.banner-caption .sub-cat-title-caption {
	font-size: 20px;
	font-weight: 100;
}
.conversation-caption a, .conversation-caption a:hover, .conversation-caption p, .banner-caption a, .banner-caption a:hover, .banner-caption p {
	color: #fff;
}
.conversation-caption .Letstalk-content, .banner-caption .Learnmore-content {
	background-color: #fff;
	color: #333;
	border-radius: 30px;
    padding: 10px;
    display: block;
    max-width: 150px;
    text-align: center;
}
.banner-caption .Learnmore-content {
	color: #fff;
	background-color: #C83A2F;
	font-size: 20px;
}
.multi-tab-container .multi-tab {
	border-right: 3px solid #fff;
	margin-left: 0;
	margin-right: 0;
	padding-left: 1%;
	padding-right: calc(1% - 3px);
}
/*Overwritten in home.css*/
/*.multi-tab-container .multi-tab:hover p {
	color: #c83a2f;
}*/
.featured-banner-items .grid-container > div:nth-of-type(6) {
	border-right: none;
}
.featured-banner-items .grid12-2 .page-content p:nth-of-type(2) {
	height: 48px;
}
/**************************[ Footer ]**************************/
#footer .no-gutter .footer.container.footer-top {
	max-width: 100%;
	width: 100%;
	margin: 0;
}
#footer .no-gutter .footer.container > .container.grid12-12 {
	margin-left: auto;
	margin-right: auto;
	display: block;
	float: none;
}
#footer .no-gutter .footer.container > .container.grid12-12 .section.clearer {
	float: none !important;
}
.footer-top-container, .footer-container {
	background-color: #f2f2f2;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #f2f2f2;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #B0B5BC;
	color: #fff;
}
.footer-links-column {
	margin-top: 30px;
}
.footer ul.bullet li {
	padding-left: 0;
	background: none;
	margin-bottom: 20px;
}
.footer h6.block-title.heading {
	font-size: 20px;
	text-transform: none;
	margin-bottom: 20px;
	height: 46px;
}
.footer .footer-titles-only h6.block-title.heading {
    padding-bottom: 30px;
    border-bottom: 1px solid #999;
	margin-bottom: 30px;
}
.footer .footer-titles-only h6.block-title.heading:last-of-type {
    border-bottom: none;
}
.footer-address, .footer-links-column ul.bullet li a {
	color: #999;
}
.footer-social .social-icon {
	margin-right: 10px;
}
.footer-bottom .item-left p {
	line-height: 80px;
}
.footer-bottom .item-right {
	height: 80px;
}
.footer-bottom .item-right img[src*="women-owned"], .footer-bottom .item-right img[src*="hub"] {
	margin-top: 20px;
}
.footer-social i.fa {
	color:#fff;
	background-color:#b0b5bc;
	border-radius:5px;
	padding-top:5px;
	font-size:20px;
	width:30px;
	height:25px;
	text-align:center;
	margin-right:30px;
}
.footer h6.block-title.heading {
	letter-spacing:normal;
	font-size:16px;
	font-weight:bold;
	height:auto;
}
.footer h6.block-title.heading + div.block-content ul.bullet li {
	margin-bottom:5px;
}
.footer h6.block-title.heading + div.block-content ul.bullet li a {
	font-weight:bold;
	font-size:14px;
}
/*Overwritten in footer.css*/
/*.footer h6.block-title.heading + div.block-content ul.bullet li a:hover {
	color:#c83a2f;
}*/
.footer-links-column.headings-only .grid12-12 {
	width:auto;
	width:initial;
	width:intrinsic;
	width:-moz-max-content;
	width:-webkit-max-content;
	margin-left:auto;
	margin-right:auto;
	float:none;
	display:block;
}
/************************** MICS **************************/


