/*Footer*/

/*Footer Override*/

.footer h6.block-title.heading+div.block-content ul.bullet li a:hover {
    color: #d9b256;
}
@media only screen and (min-width: 960px) {

.footer-links-column h6.block-title.heading, .footer-contact-column h6.block-title.heading {
    display: none;
}
.footer-links-column .footer-titles-only h6.block-title.heading {
    display: block;
}
}