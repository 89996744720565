/*Mobile styling*/
@media only screen and (max-width: 480px) {
    #header {
        position: relative;
        height: 10%;
        top: 0;
    }
    /*header items in a row*/    
    .header .item-right {
        float: right !important;
        padding: 7px 0;
       }
       #mini-cart .fa.fa-shopping-cart, .mobile-header-container .fa.fa-shopping-cart {
        height: auto !important;
    }
    /*logo left*/
    .header .logo-wrapper {
        text-align: left !important;
        }
    .header-top {
            text-align: left;
        }
    .logo img {
            width: 70%;
            padding: 0 15px;
        }
    /*minicart 0 padding*/
    .dropdown-toggle.cover>div {
            padding: 0;
        }
    /*search position*/
    .fa.fa-search {
            margin-top:5px;
            font-style: normal;
        }
    .nav-cart {
            display: none;
        }
    .header-top-container .header-top {
            line-height: normal;
            text-align: left;
            width: 100% !important;
            padding-bottom: 5px;
            padding-top: 5px;
        }
        .form-search {
            margin-left: -20px;
        }
       
    /*home contents and tab*/
    .multi-tab-container .multi-tab .keep-width, .keep-width.grid12-2 {
            width: 100% !important;
        }
    .multi-content-container > .nested-container > .grid-container {
            clear: both;
            margin-left: 2%;
            margin-right: 2%;
        }
    .main-container {
            padding-top: 0;
        }
    .multi-tab-container .multi-tab {
            cursor: pointer;
            position: relative;
            left: 0;
            right: 0;
            padding: 10px;
            transform: translate(6%, 0px);
            border: 2px solid #d9b256;
            width: 90% !important;
        }
    .page-type-home div.std {    
        position: relative;
        left: 0;
        width: 100%;
    }   

    /*Category page*/
    /*Align to center*/
    .product-search-container {
        text-align: center;
        }
    /*add to cart and more info button alignment*/
    .products-list .btn-cart {
            max-width: initial !important;
            width: auto !important;
        }
    .product-view .btn-cart span span, button.button span span {
            border: 1px solid #d9b256;
            border-radius: 7px;
            padding: 7px 12px !important;
            height: auto !important;
            line-height: 2em !important;
        }
    .mmenu-filters-wrapper {
            margin-top: 8px;
        }

    /*Product page*/
    .product-name, .short-description, .product-type-data .price-box {
        text-align: center;
    }
    .gen-tabs .acctab.current, .gen-tabs .acctab:focus, .gen-tabs .acctab:hover {
        background-color: #002469 !important;
        color: #fff !important;
    }
    /*Selection width fix*/
    .page-type-magento_layout .select2-container--default .select2-selection--single {
        width: auto !important;
    }
    /*Success message width edit*/
    #messages_product_view>ul.messages {
        margin: 0 auto;
        width: auto !important;
    }
    /*colour field text area width mobile change*/
    #cart_product_comment textarea {
        width: 260px !important;
    }

    /*cart page*/
    .cart-action-buttons #save-cart.btn-inline, .cart-action-buttons button.btn-clear.btn-inline, .cart-action-buttons button.btn-continue.btn-inline, .cart-action-buttons button.btn-proceed-checkout-action.btn-proceed-checkout.btn-checkout.btn-inline, .cart-action-buttons button.btn-update.btn-save-change.btn-inline {
        margin-right: 15px;
        margin-bottom: 10px;
    }
    .cart-action-buttons {
        text-align: center;
    }
    #shopping-cart-table .product-name .item-code {
        display: none !important;
        font-size: .8em;
    }
    .data-table tr.odd.last td.a-center {
        display: none;
    }
    .data-table tr.odd.last td.a-center + td.a-center {
        display: block;
    }

    /*minicart subtotal */
    #mini-cart .hide-below-960 .cart-total {
        visibility: visible;
        position: absolute;
        left: 35px;
        top: 4px;
        border: none;
        background-color: #d9b256;
        border-radius: 50%;
        color: #fff;
        width: 17px;
        height: 17px;
        text-align: center;
        font-size: 9px;
        line-height: 17px;
        display: inline-block !important;
    }
    #mini-cart .hide-below-960 {
        display: inline-block !important;
        visibility: hidden;
        width: 0;
        margin-top: -45px;
    }
    .empty span.price {
        padding-right: .5em;
        display: none;
    }

    /*Knowledge center accordion width edit*/
    .accordion-faq {
        margin:0;
        margin-bottom:5px;
    }
    /*grid full and 12-12 span 100% mobile*/
    .grid-full, .grid12-12, .container {
        width: 100% !important;
    }
    .main-container, .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .main .col-main, .main .sidebar {
        margin: 0 !important;
    }
}
/*Footer adjust mobile*/
@media only screen and (max-width: 479px) {
.footer-links-column h6.block-title.heading, .footer-contact-column h6.block-title.heading {
    display: block;
    }   
.footer-links-column {
    margin-top: 0 !important;
    }
    .footer-bottom .item-left p {
        line-height: normal !important;
    }
}

@media only screen and (min-width:960px) {
    .footer-links-column h6.block-title.heading, .footer-contact-column h6.block-title.heading {
        display:none;
   }
    .footer-links-column .footer-titles-only h6.block-title.heading {
        display:block;
   }
}