/*Home*/
/*Template from Noah chemicals*/

/*Home Override*/

/*h1 colour change*/
.banner-h1 a, h1, h1.banner-h1 {
    font-size: 32px;
    color: #d9b256;
}
/*Tab hover change*/
.multi-tab-container .multi-tab:hover p {
    color: #d9b256;
}

h1 {
	font-size:26px;
	font-family: 'Montserrat', sans-serif;
	font-weight:500;
	color:#d9b256;
}
.red-links a, a.red-link {
    color: #d9b256!important;
}

#scroll-to-top, #scroll-to-top:hover {
    background-color: #d9b256;
}
/*banner 2 and 4*/
.home-banner {
    position:absolute;
    left:10%;
    top:48%;
}
/*banner 1 and 3*/
.home-banner-right, .home-banner-right-white {
    position:absolute;
    right:5%;
    top:40%;
    text-align:right;
}
.home-banner-right-white h2, .home-banner-right-white p {
    color:#fff;
}
/*Tabbed content*/
.keep-width.grid12-2 {
    width: 14.66%;
}
.multi-tab-container .multi-tab {
    cursor:pointer;
    position:relative;
    left:6%;
    padding:20px;
    transform:translate(6%, 0px);
    border:2px solid #d9b256;
}
.multi-tab-container .multi-tab {
    border-right: 3px solid #d9b256 !important;
}