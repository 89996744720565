/*Product detail page*/
/*Template from Noah chemicals*/

/*Product detail page Override*/

.gen-tabs .tabs a {
    background-color:#e5e5e5;
    color:#000;
    line-height:3em;
}
.gen-tabs .tabs a.current, .gen-tabs .tabs a.active, .gen-tabs .tabs a:focus, .gen-tabs .tabs a:hover {
    color:#fff;
    background-color:#002469;
}
.gen-tabs .tabs li {
    float:left;
    margin:0;
    border-right:2px solid #fff;
}
.gen-tabs-style1 .tabs a.current {
    background-color:#002469;
    color:#fff;
    border-color:#e5e5e5;
    border-bottom:1px solid transparent;
    margin-bottom:-1px;
    padding-top:0;
    margin-top:0;
}
/*description*/
div.std.secondary-desc {
    font-size: 1.2em;
}
/*Bullet points to show in product tabs*/
#product-tabs > ul > li {
    list-style:disc;
}
ul.tabs.clearer li, ol, ul {
    list-style:none !important;
}
.gen-tabs .tabs-panels {
    background-color: #fff;
    /*text-indent: 10px;*/
}
#product-tabs > .tabs-panels > div.std > ul > li {
    margin-left: 18px;
    list-style: disc;
}

/*Remove image in featured items on product detail page*/
#itemslider-related a.product-image {
	display:none !important;
}

/*colour field styling to sit under all variant options*/
.module-product-details .grid12-0 {
    float:none;
    clear:both;
    width:73%;
    margin-left:10px;
}
#cart_product_comment .input-box {
    margin-left:3%;
}
#cart_product_comment input.input-text {
    width:100%;
}

/*colour field styling to sit under all variant options*/
.module-product-details .grid12-0 {
    float:none;
    clear:both;
    width:73%;
    margin-left:10px;
}
#cart_product_comment .input-box {
    margin-left:3%;
}
#cart_product_comment input.input-text, .xm-grid-product input.qty-box.input-text.qty {
    width:100%;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    height: 28px;
}
#cart_product_comment.cart_product_comment {
    font-weight: 700;
    font-size: 12px;
}
.product-options-bottom {
    margin-top: 20px !important;
}
/*Space between from and $ label*/
span.regularPriceTagLabel {
    padding-right: 5px;
}

/*Space above add to cart and qty button*/
.add-cart-wrapper button:last-child {
    margin-top: 18px !important;
}

.qty-wrapper.v-center {
    margin-top: 15px;
}
.page #cart_product_comment .cart_item_comment, .product-view div#cart_product_comment {
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    width: 600px;
    margin-left: 18px;
}
/*Detail options width to be the same*/
.page-type-magento_layout .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px;
    width: 600px;
}
.page-type-magento_layout .select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    width: 600px;
}
.page-type-magento_layout .select2-container--default .select2-selection--single .select2-selection__rendered {
    font-size: 12px;
}

/*Hiding product comments displaying nnn on product page*/
.productComments {
    display: none;
    }