/*Account page changes*/

/*import order screen*/
input.input-text.item_qty.quantity_field.qty-box,
input.qty, /*quick order page*/
.input-text .item_qty, /*view cart page*/
input#qty_0.input-text.item_qty 
{
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
}
.ReactTable .rt-th, .ReactTable .rt-td {
    white-space: pre-wrap !important;
}
.ReactTable * {
    font-size: 12px;
    text-align: left !important;
}

/*Quick Order Screen*/
/*font size same for price and item code*/
.rTableCell.text-right.related.unit_price span.cell-value, 
.rTableCell.text-right.related.ext_price span.cell-value,
.rTableCell.text-right.related.qtyoh span.cell-value {
font-size: 12px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px;
    font-size: 12px;
}
/*remove default address title*/
.col2-set.addresses-list.module-address-book-default-addresses h2 {
    display: none;
}

/*Transaction page align pay invoice button*/
.module-account-transhistory-content-placeholder .right {
    width:auto;
    text-align:right;
    float:none !important;
}

/*Order detail page, item code width edit*/
.nobr, .order-items .data-table thead th {
    width: 20%;
}