/*Header*/
/*Template from Noah chemicals*/

/*Header Override*/

/*Adding vertical bar after the links*/
.desktop-top-header-container .links>li>a::after {
    content: "\00a0|\00a0";
    font-size: 18px;
}
/*header top background change*/
.desktop-top-header-container .nested-container {
    background-color: #fff;
    padding-top: 5px;
}
/*remove bullet points*/
.nav-regular li.level0 {
    float:left;
    list-style:none;
}
.links>li {
    float:left;
    list-style:none;
}
.nav-regular .nav-panel--dropdown {
     list-style: none;
}
/*switch customer colour change*/
.header a, .header p, .header span {
    font-size: 14px;
    color: #d9b256;
    font-family: Montserrat,sans-serif;
}
/*remove on hold text*/
span.userOnHold {
    display:none;
}

/*Nav changing links and border from red to gold*/
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #d9b256;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #d9b256;
}
#root-wrapper a:hover span.fa:before {
	color: #d9b256;
}
#nav .nav-panel--dropdown a:hover {
	color: #d9b256;
}
/*Search*/
.form-search .input-text {
    display: block;
    height: 46px;
    line-height: 46px;
    padding: 0 46px 0 27px;
    color: #bbb;
    background-color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 10px;
    margin-top: 12px;
}
/*nav background change*/
.cms-index-index .nav-regular .nav-item--home>a, .nav-mobile .nav-item.level0.current>a, .nav-regular .nav-item.level0.active>a {
    background-color: transparent;
    color: #fff;
}
/*remove border*/
#nav, .mobnav-trigger {
    border-bottom: none;
}
/*Minicart*/
#mini-cart .actions button.button span span {
    background-color: #b0b5bc!important;
    border: 1px solid #b0b5bc;
    border-radius: 10px;
    max-height: 45px;
    font-size: 16px;
    margin-top: 10px;
}
#mini-cart .fa.fa-shopping-cart, .mobile-header-container .fa.fa-shopping-cart {
    height: 10px !important;
}

/*Hover*/

/*Links hover*/
a:hover {
    color: #d9b256;
}
#nav.nav-regular li.nav-item.level0:hover {
    border-bottom: 2px solid #d9b256;
}
.nav-regular .classic>.nav-panel--dropdown, .nav-regular .mega>.nav-panel--dropdown, .nav-regular li.level0>.nav-panel--dropdown {
    border-top: 5px solid #d9b256;
}