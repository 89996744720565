/*General*/

/*general Override*/
/*button styling for arrows, scroll to top etc*/
#cboxClose, #cboxNext, #cboxPrevious, 
#scroll-to-top, 
.accordion-style1 .opener, 
.add-to-links span.icon, .add-to-links.addto-icons a, 
.buttons-set .back-link small, .collapsible .opener, 
.gen-slider-arrows1 .direction-nav a, .gen-slider-arrows1 .direction-nav a.disabled:hover, .gen-slider-arrows2 .direction-nav a, .gen-slider-arrows2 .direction-nav a.disabled:hover, .gen-slider-arrows3 .direction-nav a, .gen-slider-arrows3 .direction-nav a.disabled:hover, 
.slider-arrows1 .owl-controls div, .slider-arrows1 .owl-controls.clickable div.disabled:hover, .slider-arrows2 .owl-controls div, .slider-arrows3 .owl-controls div, .slider-arrows3 .owl-controls.clickable div.disabled:hover, .slider-pagination1 .owl-controls .owl-page span, .slider-pagination2 .owl-controls .owl-page span, 
.sorter .sort-by .category-asc, .sorter .sort-by .category-desc, .sorter .view-mode a {
    background-color: #d9b256;
}
#cboxClose:hover, #cboxNext:hover, #cboxPrevious:hover, 
#scroll-to-top:hover, .accordion-style1 .opener:hover, 
.add-to-links .feature-icon-hover:hover span.icon, .add-to-links span.icon-hover:hover, .add-to-links.addto-icons a:hover, 
.buttons-set .back-link:hover small, .collapsible .opener:hover, 
.gen-slider-arrows1 .direction-nav a:hover, .gen-slider-arrows2 .direction-nav a:hover, .gen-slider-arrows3 
.direction-nav a:hover, .pager .pages li a:hover, 
.slider-arrows1 .owl-controls.clickable div:hover, .slider-arrows2 .owl-controls.clickable div:hover, .slider-arrows3 .owl-controls.clickable div:hover, 
.slider-pagination1 .owl-controls.clickable .owl-page:hover span, .slider-pagination2 .owl-controls.clickable .owl-page:hover span, 
.sorter .sort-by .category-asc:hover, .sorter .sort-by .category-desc:hover, .sorter .view-mode a:hover {
    background-color: #d9b256;
}
/*Quick order page*/
.inline-search-results-quickorder .highlight {
    color: #000 !important;
    background-color: #d9b256 !important;
}
/*Remove bullet point in registration/login page*/
.module-login-form .content ul.form-list {
    list-style:none;
}

/*Register page back button colour change*/
.buttons-set .back-link small {
    background-color: transparent !important;
}
.buttons-set .back-link:hover small { 
    background-color: #d9b526 !important;
}
.main-container {
    background-color: #fff;
}
/*Hidden as per Emma request on 29/9/21*/
/*a, body, i, p, span, u {
    font-size: 12px !important;
}
h1 {
    font-size: 1.6666em !important;
    line-height: 1.25em;
}
h2 {
    font-size: 1.2em !important;
    line-height: 1em;
}*/

/*Order complete page*/
#custom_comment_container span {
    line-height:normal !important;
}
tr.sub-total-item td.a-right, .grant-total-amount td, .grant-total-amount th {
    vertical-align:middle;
}
.a-right, .data-table thead th {
    font-size:12px !important;
}