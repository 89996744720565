/* Category */



.page-type-category .qty-wrapper {
font-size:1em;
margin:5px 0 10px;
}

.products-line .product-image img {
width:44%;
}

.page-type-category .products-line .addto-links-icons.addto-onimage {
float:left;
margin-top:-32px;
position:relative;
z-index:1;
padding-bottom:4px;
padding-right:4px;
margin-left:65px;
}

.page-type-category .products-line .qty-wrapper {
float:right;
margin-top:-33px;
margin-right:29px;
}

.page-type-category .category-title {
    /*margin-bottom: 1%;*/
}

.page-type-category .std dl,
.page-type-category .std p,
.page-type-category .std address, .std blockquote {
    margin: 0;
}

.page-type-category .horizontal-section .page-content
{
    margin: 0px;
    padding: 0px;
}
.module-category-misc > div {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.module-category-misc > div:first-of-type {
    -webkit-box-ordinal-group: 2;
        -moz-box-ordinal-group: 2;
        box-ordinal-group: 2;
}
.module-category-misc > div:first-of-type + div {
    -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
        box-ordinal-group: 1;
}
ul.category-products-listing {
    padding-top: 35px;
}
ul.category-products-listing > li {
    padding: 5px;
}
ul.category-products-listing > li:nth-of-type(even) {
    background-color: #f2f2f2;
}
.cat-title-column.grid12-8 {
    width: 64.67% !important;
}
.cat-filter-column.grid12-4 {
    width: 31.33% !important;
}
/*Category list buttons*/
.product-view .btn-cart span span, button.button span span {
    border: 1px solid #d9b256;
    border-radius: 7px;
}
.product-view .btn-cart span, button.button span {
    margin: 0;
    background-color: #d9b256;
    border-radius: 10px;
    padding: 0;
}
.pager .pages li a:hover {
    background-color: #d9b256;
    color: #000;
}
/*sort view hover background colour*/
#cboxClose:hover, #cboxNext:hover, #cboxPrevious:hover, #scroll-to-top:hover, .accordion-style1 .opener:hover, .add-to-links .feature-icon-hover:hover span.icon, .add-to-links span.icon-hover:hover, .add-to-links.addto-icons a:hover, .buttons-set .back-link:hover small, .collapsible .opener:hover, .gen-slider-arrows1 .direction-nav a:hover, .gen-slider-arrows2 .direction-nav a:hover, .gen-slider-arrows3 .direction-nav a:hover, .pager .pages li a:hover, .slider-arrows1 .owl-controls.clickable div:hover, .slider-arrows2 .owl-controls.clickable div:hover, .slider-arrows3 .owl-controls.clickable div:hover, .slider-pagination1 .owl-controls.clickable .owl-page:hover span, .slider-pagination2 .owl-controls.clickable .owl-page:hover span, .sorter .sort-by .category-asc:hover, .sorter .sort-by .category-desc:hover, .sorter .view-mode a:hover {
    background-color: #d9b256;
}