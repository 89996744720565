/*Category*/

/*Category Override*/
/*price colour change*/
.price-box .regular-price .price,
.price-box .minimal-price .price,
.price-box-bundle .full-product-price .price { color: #d9b256; }

/*breadcrumb hover*/
a:hover,
.vertnav li.current > a
{
			color: #d9b256;
	}

/*description*/
.products-list .desc {
    margin: 15px 0;
    font-size: 1.2em;
}

#filter_list_box li a {
	font-size: 1.2em;
}
/*Remove bullet point*/
ul.category-products-listing {
    padding-top: 35px;
    list-style: none;
}

