/* Content Page css */

/* FAQs */

.accordion-faq {
    background-color:#fff;
    color:#444;
    cursor:pointer;
    padding:10px;
    width:100%;
    border:1px solid #b3b3b3;
    text-align:left;
    outline:none;
    font-size:16px;
    transition:0.4s;
	font-family: 'Montserrat', sans-serif;
    font-weight:400;
    margin:5px;
    box-shadow:1px 1px 1px #d4d4d4;
}
.accordion-faq.active {
    font-weight:bold;
}
.accordion-faq:after, .accordion-faq:before {
    color:#777;
    font-weight:bold;
    float:right;
    margin-left:5px;
}
.accordion-faq:before {
    content:'\25BC';
}
.accordion-faq.active:before {
    content:'\25B2';
}
.panel-faq, .download-tabs {
    padding:15px 18px;
    background-color:white;
    overflow:hidden;
    transition:max-height 0.2s ease-out;
    font-size:15px;
}
.panel-faq {
    display:none;
}
.download-title {
	font-family: 'Montserrat', sans-serif;
    font-size: 25px !important;
    letter-spacing: 1.5px !important;
    font-weight: 400;
}
.panel-faq {
    display: none;
}
.open + .panel-faq {
    display: block;
}
